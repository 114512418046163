const FORM = {
    onReady: function() {
        FORM.datePicker();
        FORM.openSelect();
        FORM.setSelectValue();
    },
    datePicker: function() {
        let datePickerTriggers = document.querySelectorAll('.form__input--datepicker');
        datePickerTriggers.forEach(datePickerTrigger => {
            new Datepicker(datePickerTrigger, {
                'format' : 'dd.mm.yy',
                'minDate' : 'currentDate',
                'daysOfWeekDisabled' : [0,6],
                'prevArrow' : '←',
                'nextArrow' : '→',
                'todayBtn' : true,
                'todayBtnMode' : 1,
                'autohide' : true,
                'orientation' : ( window.innerWidth > 960 ) ? datePickerTrigger.dataset.orientation : 'auto',
                'language' : 'de'
            });
        });
    },
    openSelect: function() {
        let selectInputs = document.querySelectorAll('.form__input--select');
        selectInputs.forEach(selectInput => {
            selectInput.addEventListener('click', () => {
                FORM.closeSelect();
                let datalist = selectInput.dataset.list;
                let datalistContainer = document.getElementById(datalist);
                selectInput.classList.toggle('open');
                datalistContainer.classList.toggle('open');
            });
        });
        document.addEventListener('click', event => {
            if (event.target.classList.contains('form__input--select') && event.target.classList.contains('open') ) return;
            FORM.closeSelect();
        });
    },
    closeSelect: function() {
        let openDatalists = document.querySelectorAll('.form__datalist.open');
        openDatalists.forEach(openDatalist => {
            openDatalist.classList.remove('open');
        });
        let openSelectInputs = document.querySelectorAll('.form__input--select.open');
        openSelectInputs.forEach(openSelectInput => {
            openSelectInput.classList.remove('open');
        });
    },
    setSelectValue: function() {
        let values = document.querySelectorAll('.form__datalist__item');
        values.forEach(value => {
            value.addEventListener('click', () => {
                let id = value.parentNode.id;
                let input = document.querySelector(`input[data-list=${id}]`);
                input.value = value.dataset.value;
            });
        });
    },
    validate: function(el, event) {
        event.preventDefault();
        el.disabled = true;
        FORM.overlayLoading();
        this.form  = ( el.parentElement.classList.contains('form') || el.parentElement.classList.contains('form--sticky') ) ? el.parentElement : el.parentElement.parentElement;
        this.email = this.form.querySelector('input[type="email"]');
        this.phone = this.form.querySelector('input[type="tel"]');
        /******************************
         * Reset Form
         ******************************/
        let inputs    = this.form.getElementsByTagName('input');
        let textareas = this.form.getElementsByTagName('textarea');
        for (i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('error');
        }
        for (j = 0; j < textareas.length; j++) {
            textareas[j].classList.remove('error');
        }
        var error = 0;
        /******************************
         * Check Required
         ******************************/
        for (i = 0; i < inputs.length; i++) {
            if (inputs[i].required && inputs[i].value == '') {
                inputs[i].classList.add('error');
                error++
            }
        }
        for (j = 0; j < textareas.length; j++) {
            if (textareas[j].required && textareas[j].value == '') {
                textareas[j].classList.add('error');
                error++
            }
        }
        /******************************
         * Check Email Format
         ******************************/
        if ( this.email !== null ) {
            var regex_email = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (regex_email.test( this.email.value ) == false) {
                this.email.classList.add('error');
                error++;
            }
        }
        /******************************
         * Check Phone Format + Length
         ******************************/
        if ( this.phone !== null ) {
            var regex_phone = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
            if (regex_phone.test( this.phone.value ) == false) {
                this.phone.classList.add('error');
                error++;
            }
            if (this.phone.value.length < 8) {
                this.phone.classList.add('error');
                error++;
            }
        }
        if (error > 0) {
            document.getElementById('overlay-loading').remove();
            el.disabled = false;
            return;
        } else {
            FORM.sendData(el);
        }
    },
    response: function(markup) {
        FORM.form.insertAdjacentHTML('afterend', markup);
        let remover = FORM.form.dataset.remover;
        let remove  = document.querySelectorAll(`[data-remove=${remover}]`);
        remove.forEach(element => {
            element.remove();
        });
        FORM.form.remove();
    },
    sendData: function(el) {
        let getUrl      = window.location;
        let baseUrl     = getUrl.protocol + '//' + getUrl.hostname + '/';
        let formData    = FORM.serialize(FORM.form);
        const timer     = 1500; // minutes
        const urlParams = new URLSearchParams(window.location.search);
        /******************************
         * Reservation Start
         ******************************/
        if ( el.classList.contains('js-reservation-start') ) {
            window.location.href = baseUrl + 'anfrage/?' + formData + '&init-time=' + Date.now();
            return;
        /******************************
         * Transporter Start
         ******************************/
        } else if ( el.classList.contains('js-transporter-start') ) {
            window.location.href = baseUrl + 'anfrage/?' + formData + '&init-time=' + Date.now();
            return;
        /******************************
         * Reservation End
         ******************************/
        } else if ( el.classList.contains('js-reservation-end') ) {
            var handler    = 'reservation';
            var success    = encodeURI('Wir werden Ihre Anfrage so schnell wie möglich bearbeiten und uns in Kürze bei Ihnen melden.');
            var error      = encodeURI('Zeitüberschreitung, Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es nochmal.');
            var successURI = baseUrl + '?success=' + success;
            var errorURI   = baseUrl + '?error=' + error;
            var initTime   = urlParams.get('init-time');
            // Timer to end Reservation if it takes to long
            if ( Date.now() > ( parseInt(initTime) + (1000 * 60 * timer) ) ) {
                window.location.href = baseUrl + '?error=' + error;
                return;
            }
        /******************************
         * Transporter End
         ******************************/
        } else if ( el.classList.contains('js-transporter-end') ) {
            var handler    = 'transporter';
            var success    = encodeURI('Wir werden Ihre Anfrage so schnell wie möglich bearbeiten und uns in Kürze bei Ihnen melden.');
            var error      = encodeURI('Zeitüberschreitung, Ihre Sitzung ist abgelaufen. Bitte versuchen Sie es nochmal.');
            var successURI = baseUrl + '?success=' + success;
            var errorURI   = baseUrl + '?error=' + error;
            var initTime   = urlParams.get('init-time');
            // Timer to end Reservation if it takes to long
            if ( Date.now() > ( parseInt(initTime) + (1000 * 60 * timer) ) ) {
                window.location.href = baseUrl + '?error=' + error;
                return;
            }
        /******************************
         * Contact
         ******************************/
        } else if ( el.classList.contains('js-contact') ) {
            var handler = 'contact';
            var markup = `
                <div class="form--response">
                    <h2>Danke für Ihre Nachricht</h2>
                    <p>Wir werden diese so schnell wie möglich bearbeiten und uns in Kürze bei Ihnen melden.</p>
                </div>`;
            FORM.response(markup);
        }
        let xhr = new XMLHttpRequest();
        xhr.open('POST', baseUrl + 'inc/handler.Mailer.php?data=' + handler, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = (e) => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (typeof successURI !== 'undefined' && successURI !== null) {
                        window.location.href = successURI;
                    } else {
                        let loadingOverlay = document.getElementById('overlay-loading');
                        (loadingOverlay !== null) && loadingOverlay.remove();
                    }
                } else {
                    var serverError = encodeURI('Bei Ihrer Anfrage gab es interne Server Probleme. Bitte versuchen Sie es nochmal oder nehmen Sie direkt zu uns Kontakt auf');
                    window.location.href = baseUrl + '?error=' + serverError;
                }
            }
        };
        xhr.onerror = (e) => {
            var serverError = encodeURI('Bei Ihrer Anfrage gab es interne Server Probleme. Bitte versuchen Sie es nochmal oder nehmen Sie direkt zu uns Kontakt auf');
            window.location.href = baseUrl + '?error=' + serverError;
        };
        xhr.send(formData);
    },
    serialize: function(form) {
        var serialized = [];
        for (var i = 0; i < form.elements.length; i++) {
            var field = form.elements[i];
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value));
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value));
            }
        }
        return serialized.join('&');
    },
    overlayLoading: function() {
        let markup = `
        <div class="overlay" id="overlay-loading">
            <div id="loading">
                <div class='overlay__container--loader'>
                    <div class="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>`;
        document.body.insertAdjacentHTML('afterbegin', markup);
    }
}