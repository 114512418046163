const TOGGLER = {
    onReady: function() {
        TOGGLER.listener();
    },
    listener: function() {
        let listener = document.querySelectorAll('.js-toggler');
        listener.forEach(element => {
            element.addEventListener('click', event => {
                event.preventDefault();
                let container = document.getElementById(element.dataset.toggle);
                container.classList.toggle('open');
                document.body.classList.toggle('noscroll');
            });
        });
    }
}