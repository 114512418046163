const COOKIE = {
    setCookie: function(name, value, days) {
        let expires = new Date();
        expires.setTime(expires.getTime() + (days * 86400000)); //24 * 60 * 60 * 1000 (milliseconds)
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=/;secure=true';
    },
    getCookie: function(cookieName) {
        var name = cookieName + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    deleteCookie: function(name, domain) {
        document.cookie = name + "=;domain=" + domain + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
    }
}