const TRACKING = {
    cookieName : '_user_cookie_settings',
    optin: document.getElementById('optin'),
    acceptButtons: document.querySelectorAll('.optin__button'),
    settings: document.getElementById('optin__settings'),
    showSettings: document.getElementById('optin__show-settings'),
    gaProperty: 'UA-80831471-9',
    hotJarId: 2075278,
    onReady: function() {
        if (TRACKING.optin !== null) {
            TRACKING.showOptin();
            TRACKING.showOptions();
            TRACKING.acceptOptin();
        }
        TRACKING.gaOptout();
    },
    showOptin: function() {
        if ( document.cookie.indexOf(TRACKING.cookieName) != -1 ) {
            TRACKING.optin.remove();
            let cookieValueStr = TRACKING.getCookie(TRACKING.cookieName);
            let cookieValueArr = cookieValueStr.split(',');
            TRACKING.activateTracking(cookieValueArr);
        } else {
            TRACKING.optin.style.display = 'block';
        }
    },
    acceptOptin: function() {
        TRACKING.acceptButtons.forEach(button => {
            button.addEventListener('click', element => {
                element.preventDefault();
                let cookieSettingsArr = [];
                TRACKING.settings.querySelectorAll('input').forEach( el => {
                    if (el.checked) {
                        cookieSettingsArr.push(el.value);                    
                    }
                });
                let cookieSettingsStr = cookieSettingsArr.toString();
                TRACKING.setCookie(TRACKING.cookieName, cookieSettingsStr, 7);
                TRACKING.optin.remove();
                TRACKING.activateTracking(cookieSettingsArr);
                window.location.reload();
            });
        });
    },
    showOptions: function() {
        TRACKING.showSettings.addEventListener('click', () => {
            TRACKING.settings.style.display = 'block';
            TRACKING.showSettings.remove();
            TRACKING.settings.querySelectorAll('input').forEach( el => {
                el.checked = false;
            });
        });
    },
    getCookie: function(name) {
        let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
    },
    setCookie: function(name, value, days) {
        let expires = new Date();
        expires.setTime(expires.getTime() + (days * 86400000)); // 24 * 60 * 60 * 1000 (milliseconds)
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=/;secure=true';
    },
    deleteCookie: function(name, domain) {
        document.cookie = name + "=;domain=" + domain + ";expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure=true";
    },
    activateTracking: function(cookieSettingsArr) {
        if ( cookieSettingsArr.includes('google_analytics') ) {
            TRACKING.googleAnalytics();
            TRACKING.gaEvents();
        }
        if ( cookieSettingsArr.includes('google_maps') ) {
            MAPS.build();
        }
        if ( cookieSettingsArr.includes('hotjar') ) {
            TRACKING.hotJar();
        }
        if ( cookieSettingsArr.includes('facebook_pixel') ) {
            TRACKING.facebook();
        }
    },
    googleAnalytics: function() {
        if ( document.cookie.indexOf('_ga_optout') == -1 ) {
            (function(i, s, o, g, r, a, m) {
                i['GoogleAnalyticsObject'] = r;
                i[r] = i[r] || function() {
                    (i[r].q = i[r].q || []).push(arguments)
                }, i[r].l = 1 * new Date();
                a = s.createElement(o),
                    m = s.getElementsByTagName(o)[0];
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m)
            })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
            ga('create', TRACKING.gaProperty, 'auto');
            ga('set', 'anonymizeIp', true);
            ga('send', 'pageview');
        }
    },
    hotJar: function() {
        (function(h, o, t, j, a, r) {
            h.hj = h.hj || function() {
                (h.hj.q = h.hj.q || []).push(arguments) 
            };
            h._hjSettings = { hjid: TRACKING.hotJarId, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    },
    facebook: function() {
        !function(f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'); 
        fbq('init', '3444005169175071');
        fbq('track', 'PageView');
    },
    gaOptout: function() {
        let optoutLink = document.getElementById('ga-optout');
        if (optoutLink === null) return;
        optoutLink.addEventListener('click', el => {
            el.preventDefault();
            TRACKING.setCookie('_ga_optout', 'true', 7);
            let domain = window.location.hostname.replace('www', '');
            TRACKING.deleteCookie('_ga', domain);
            TRACKING.deleteCookie('_gid', domain);
            TRACKING.deleteCookie('_gat', domain);
            alert('Google Analytics wurde deaktiviert');
        });
    },
    gaEvents: function() {
        /******************************
        * EVENT: IN VIEW -> SCROLL
        ******************************/ 
        var maxScrollDepth = 0;
        var maxScrollPercentRound = 0;
        document.addEventListener('scroll', () => {
            var windowHeight       = window.innerHeight;
            var currentScrolldepth = window.scrollY + windowHeight;
            var documentHeight     = document.body.scrollHeight;
            maxScrollDepth         = (currentScrolldepth > maxScrollDepth) ? currentScrolldepth : maxScrollDepth;
            var maxScrollPercent   = maxScrollDepth / documentHeight * 100;
            var currentScrollPercentRound = Math.round(maxScrollPercent / 10) * 10;
            if (currentScrollPercentRound > maxScrollPercentRound) {
                maxScrollPercentRound = currentScrollPercentRound;
                ga('send', {
                    hitType:       'event',
                    eventCategory: 'IN VIEW',
                    eventAction:   'SCROLL',
                    eventLabel:    maxScrollPercentRound
                });
            }
        });
        /******************************
        * EVENT: IN VIEW -> FOOTER
        ******************************/   
        const footer    = document.querySelector('footer');
        var seenCounter = 0; 
        window.addEventListener('scroll', () => {
            if ( TRACKING.elementInViewport(footer) ) {
                seenCounter++;
                if ( seenCounter === 1 ) {
                    ga('send', {
                        hitType:       'event',
                        eventCategory: 'IN VIEW',
                        eventAction:   'FOOTER',
                        eventLabel:    'true'
                    });
                }
            }
        });
        /******************************
        * EVENT: RESERVATION -> START
        ******************************/ 
        let resStart = document.querySelectorAll('.js-reservation-start');
        resStart.forEach(element => {
            element.addEventListener('click', () => { 
                ga('send', {
                    hitType:       'event',
                    eventCategory: 'RESERVATION',
                    eventAction:   'START',
                    eventLabel:    'PKW'
                });
            });
        });
        /******************************
        * EVENT: RESERVATION -> START
        ******************************/ 
        let transStart = document.querySelectorAll('.js-transporter-start');
        transStart.forEach(element => {
            element.addEventListener('click', () => { 
                ga('send', {
                    hitType:       'event',
                    eventCategory: 'RESERVATION',
                    eventAction:   'START',
                    eventLabel:    'TRANSPORTER'
                });
            });
        });
        /******************************
        * EVENT: RESERVATION -> END
        ******************************/ 
        let resEnd = document.querySelectorAll('.js-reservation-end');
        resEnd.forEach(element => {
            element.addEventListener('click', () => { 
                ga('send', {
                    hitType:       'event',
                    eventCategory: 'RESERVATION',
                    eventAction:   'END',
                    eventLabel:    'PKW'
                });
            });
        });
        /******************************
        * EVENT: RESERVATION -> END
        ******************************/ 
        let transEnd = document.querySelectorAll('.js-transporter-end');
        transEnd.forEach(element => {
            element.addEventListener('click', () => { 
                ga('send', {
                    hitType:       'event',
                    eventCategory: 'RESERVATION',
                    eventAction:   'END',
                    eventLabel:    'TRANSPORTER'
                });
            });
        });
    },
    elementInViewport: function(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;
        while(el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }
        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }
}