const NAV = {
    menu: document.getElementById('menu'),
    burger: document.getElementById('burger'),
    onReady: function() {
        NAV.hasSubNav();
        NAV.toggleBurger();
        NAV.closeMobileNavOnSubItemClick();
        NAV.showOpeningsOnMobile();
    },
    hasSubNav: function() {
        let hasSubNavs = document.querySelectorAll('.has-sub-nav');
        if (window.innerWidth > 768) {
            hasSubNavs.forEach(hasSubNav => {
                hasSubNav.addEventListener('mouseover', () => {
                    hasSubNav.classList.add('open');
                    hasSubNav.nextElementSibling.classList.add('open');
                });
            });
            let navItems = document.querySelectorAll('.menu__navigation__item');
            navItems.forEach(navItem => {
                navItem.addEventListener('mouseover', () => {
                    if ( navItem.classList.contains('open') ) return;
                    NAV.closeSubNav();
                });
            });
            document.addEventListener('click', event => {
                NAV.closeSubNav();
            });
        } else {
            hasSubNavs.forEach(hasSubNav => {
                let markup = '<span class="has-sub-nav__indicator"> + </span>';
                hasSubNav.insertAdjacentHTML('beforeend', markup);
            });
            let hasSubNavIndicators = document.querySelectorAll('.has-sub-nav__indicator');
            hasSubNavIndicators.forEach(hasSubNavIndicator => {
                hasSubNavIndicator.addEventListener('click', event => {
                    event.preventDefault();
                    hasSubNavIndicator.classList.toggle('open');
                    hasSubNavIndicator.parentNode.nextElementSibling.classList.toggle('open');
                });
            });
        }
    },
    closeSubNav: function() {
        let openSubNavs   = document.querySelectorAll('.menu__sub-navigation.open');
        let openMenuItems = document.querySelectorAll('.has-sub-nav.open');
        openSubNavs.forEach(openSubNav => {
            openSubNav.classList.remove('open');
        })
        openMenuItems.forEach(openMenuItem => {
            openMenuItem.classList.remove('open');
        });
    },
    closeMobileNavOnSubItemClick: function() {
        if (window.innerWidth > 768) return;
        let subNavItems = document.querySelectorAll('.menu__sub-navigation__item');
        subNavItems.forEach(subNavItem => {
            subNavItem.addEventListener('click', () => {
                NAV.burger.classList.toggle('open');
                NAV.menu.classList.toggle('open');
                document.body.classList.toggle('noscroll');
                let hasSubNavIndicators = document.querySelectorAll('.has-sub-nav__indicator');
                hasSubNavIndicators.forEach(hasSubNavIndicator => {
                    hasSubNavIndicator.classList.remove('open');
                    hasSubNavIndicator.parentNode.nextElementSibling.classList.remove('open');
                });
            });
        });
    },
    toggleBurger: function() {
        if ( NAV.burger === null ) return;
        NAV.burger.addEventListener('click', () => {
            NAV.burger.classList.toggle('open');
            NAV.menu.classList.toggle('open');
            document.body.classList.toggle('noscroll');
        });
    },
    showOpeningsOnMobile: function() {
        if (window.innerWidth > 768) return;
        let trigger = document.getElementById('js-opening-trigger');
        let container = document.getElementById('js-opening-container');
        if (trigger === null) return;
        if (container === null) return;
        trigger.addEventListener('click', () => {
            container.classList.toggle('fixed');
            if ( container.classList.contains('fixed') ) {
                document.body.appendChild(container);
            } else {
                trigger.appendChild(container);
            }
        });
        window.addEventListener('scroll', () => {
            if (window.scrollY < 70) return;
            container.classList.remove('fixed');
        });
    }
}