const SCROLLER = {
    scrollers: document.querySelectorAll('.js-scroller'),
    items: document.querySelectorAll('.scroller__item__view'),
    // jumpmarks: document.querySelectorAll('[data-jumpmark]'),
    current: null,
    momentumID: null,
    onReady() {
        // SCROLLER.smoothJump();
        SCROLLER.fastClick();
        SCROLLER.scrollCards();
        if (SCROLLER.isTouchScreendevice()) return;
        SCROLLER.dragCards();
        SCROLLER.wheelCards();
        SCROLLER.anchors();
        SCROLLER.preventImageDrag();
    },
    isTouchScreendevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;      
    },
    fastClick() {
        SCROLLER.items.forEach(item => {            
            item.addEventListener('mousedown', event => {
                timeStart = new Date();
                xStart    = event.x;
            });
            item.addEventListener('mouseup', event => {
                timeEnd = new Date();
                xEnd    = event.x;
            });
            item.addEventListener('click', event => {
                var duration = (timeEnd - timeStart) / 1000;
                var x        = Math.abs(xStart - xEnd);
                (duration < 0.375 && x < 50 && (typeof item.dataset.href != 'undefined')) && (window.location = item.dataset.href);
            });
        });
    },
    dragCards() {
        let isDown = false;
        let startX;
        let startTime;
        let scrollLeft;
        SCROLLER.scrollers.forEach(scroller => {
            /*********************
            * Mouse DOWN
            *********************/
            scroller.addEventListener('mousedown', event => {
                isDown = true;
                startX = event.pageX - scroller.offsetLeft;
                scrollLeft = scroller.scrollLeft;
                scroller.style.scrollSnapType = 'unset';
                SCROLLER.cancelMomentumTracking();
            });
            /*********************
            * Mouse LEAVE
            *********************/
            scroller.addEventListener('mouseleave', () => {
                isDown = false;
                let anchors = scroller.querySelectorAll('a');
                setTimeout(() => {
                    anchors.forEach(anchor => {
                        anchor.classList.remove('prevented');
                    });
                    scroller.classList.remove('dragging');
                }, 10);
            });
            /*********************
            * Mouse UP
            *********************/
            scroller.addEventListener('mouseup', event => {              
                isDown = false;
                let anchors = scroller.querySelectorAll('a');
                setTimeout(() => {
                    anchors.forEach(anchor => {
                        anchor.classList.remove('prevented');
                    });
                    scroller.classList.remove('dragging');
                }, 10);
                SCROLLER.current = scroller;
                SCROLLER.beginMomentumTracking(); // Start a frame loop to continue drag momentum 
            });
            /*********************
            * Mouse MOVE
            *********************/
            scroller.addEventListener('mousemove', event => {
                if (!isDown) return;
                // event.preventDefault();
                scroller.classList.add('dragging');
                let anchors = scroller.querySelectorAll('a');
                anchors.forEach(anchor => {
                    anchor.classList.add('prevented');
                });
                const x    = event.pageX - scroller.offsetLeft;
                const walk = (x - startX); // * 3 - add speed 
                // Store the previous scroll position
                var prevScrollLeft = scroller.scrollLeft; 
                scroller.scrollLeft = scrollLeft - walk;
                // Compare change in position to work out drag speed 
                SCROLLER.velX = scroller.scrollLeft - prevScrollLeft; 
            });
        });
    },
    beginMomentumTracking(){
        SCROLLER.cancelMomentumTracking();
        SCROLLER.momentumID = requestAnimationFrame(SCROLLER.momentumLoop); 
    },
    momentumLoop() {
        SCROLLER.current.scrollLeft += SCROLLER.velX; // Apply the velocity to the scroll position
        SCROLLER.velX *= 0.8; // Slow the velocity 
        if (Math.abs(SCROLLER.velX) > 0.5) { // Still moving?
            momentumID = requestAnimationFrame(SCROLLER.momentumLoop); // Keep looping 
        }
    },
    cancelMomentumTracking() {
        cancelAnimationFrame(SCROLLER.momentumID);
    },
    wheelCards() {
        SCROLLER.scrollers.forEach(scroller => { 
            scroller.addEventListener('wheel', () => {
                SCROLLER.cancelMomentumTracking();
                scroller.style.scrollSnapType = 'x mandatory';
            });
        }); 
    },
    anchors() {
        let anchors = document.querySelectorAll('a');
        anchors.forEach(anchor => {
            anchor.addEventListener('click', event => {
                if ( anchor.classList.contains('prevented') ) {
                    event.preventDefault();
                    return;
                }
            });
        });
    },
    preventImageDrag() {
        let images = document.querySelectorAll('[draggable=false]');
        images.forEach(image => {
            image.addEventListener('dragstart', event => {
                event.preventDefault();
                return false;
            });
        });
    },
    scrollCards() {
        const lefties  = document.querySelectorAll('.scroller-nav--left');
        const righties = document.querySelectorAll('.scroller-nav--right');
        lefties.forEach(lefty => {
            let scroller     = lefty.parentNode.querySelector('.scroller');
            let scrollOffset = -1 * parseInt(getComputedStyle(scroller).getPropertyValue('--width'));
            lefty.addEventListener('click', () => {
                SCROLLER.move({
                    scroller: scroller,
                    offset: scrollOffset
                });
            });
        });
        righties.forEach(righty => {
            let scroller     = righty.parentNode.querySelector('.scroller');
            let scrollOffset = parseInt(getComputedStyle(scroller).getPropertyValue('--width'));
            righty.addEventListener('click', () => {
                SCROLLER.move({
                    scroller: scroller,
                    offset: scrollOffset
                });
            });
        });
    },
    move({ scroller, offset }) {
        // Check browser support
        if (CSS.supports('scroll-behavior', 'smooth')) {
            scroller.style.scrollSnapType = 'x mandatory';
            scroller.scrollBy({ top: 0, left: offset, behavior: 'smooth' });
        } else {
            // macOS Safari workaround
            // 1. deactivate scroll-snap-type
            // 2. scroll using smoothscroll-polyfill
            // 3. set a timeout which is about the time of the animation
            // 4. set scroll-snap-type for the cards to snap
            let newOffset = offset < 0 ? offset - 24 : offset + 24; // offset + padding
            scroller.style.scrollSnapType = 'unset';
            scroller.scrollBy({ top: 0, left: newOffset, behavior: 'smooth' });
            setTimeout(() => {
                scroller.style.scrollSnapType = 'x mandatory';
            }, 500);        
        }
    },
    smoothJump() {
        SCROLLER.jumpmarks.forEach(mark => {
            mark.addEventListener('click', event => {
                event.preventDefault();
                SCROLLER.cancelMomentumTracking();
                let jumpTo = document.getElementById(mark.dataset.jumpmark);
                (jumpTo !== null) && jumpTo.scrollIntoView({ behavior: 'smooth' });
            });
        });
    }
}