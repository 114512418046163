const TINYSLIDER = {
    onReady: function() {
        TINYSLIDER.calcSlideWidth();
        window.addEventListener('resize', () => {
            TINYSLIDER.calcSlideWidth();
        });
        TINYSLIDER.slider();
        TINYSLIDER.playVideo();
    },
    calcSlideWidth: function() {
        if (document.querySelector('.js-tiny-slider') === null) return;
        if (window.innerWidth > 1024) return;
        let slides = document.querySelectorAll('.js-tiny-slider__item');
        slides.forEach(slide => {
            slide.style.maxWidth = `calc( ${window.innerWidth}px - (var(--gutter-space) * 2) )`;
        });
    },
    slider: function() {
        if (document.querySelector('.js-tiny-slider') === null) return;
        let sliders = document.querySelectorAll('.js-tiny-slider__slides');
        sliders.forEach(slider => {
            let controlsContainer = slider.parentNode.querySelector('.js-tiny-slider__controls');
            let dotsContainer     = slider.parentNode.querySelector('.js-tiny-slider__dots');
            let tinySlider = tns({
                container: slider,
                controlsContainer: controlsContainer,
                navContainer: dotsContainer,
                loop: false,
                items: 1,
                mouseDrag: true,
                autoWidth: true,
                center: true,
                speed: 750,
                autoHeight: true,
                preventScrollOnTouch: 'auto'
            });
        })
    },
    playVideo: function() {
        let sliderControls = document.querySelectorAll('.js-tiny-slider__controls');
        sliderControls.forEach(sliderControl => {
            sliderControl.addEventListener('click', () => {
                setTimeout(() => {
                    let activeSlide = document.querySelector('.tns-slide-active');
                    let videos = document.querySelectorAll('.slider__video');
                    if ( activeSlide.firstElementChild.classList.contains('slider__video') ) {
                        videos.forEach(video => {
                            video.pause();
                        });
                        activeSlide.firstElementChild.play();
                    } else {
                        videos.forEach(video => {
                            video.pause();
                        });
                    }
                }, 100);
            });
        });
    }
}