const MAPS = {
    el: document.getElementById('map'),
    lat: 52.5602805695908,
    lang: 13.460408942006524,
    api: 'AIzaSyBxba-cf3WztPcvN0sZyfI63Ez0ZM8uelI',
    acceptButton: document.getElementById('activate-maps'),
    onReady() {
        if (MAPS.acceptButton !== null) {
            MAPS.acceptButton.addEventListener('click', el => {
                el.preventDefault();
                MAPS.build();
            });
        }
    },
    build() {
        if (MAPS.el !== null) {
            let script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${MAPS.api}&callback=initMap`;
            script.defer = true;
            script.async = true;
            window.initMap = function() {
                const map = new google.maps.Map(MAPS.el, {
                    center: {
                        lat: MAPS.lat, 
                        lng: MAPS.lang
                    },
                    zoom: 15,
                    disableDefaultUI: false,
                    styles: [{
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#212121"
                        }]
                    }, {
                        "elementType": "labels.icon",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }, {
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#212121"
                        }]
                    }, {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#9e9e9e"
                        }]
                    }, {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#bdbdbd"
                        }]
                    }, {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#181818"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#343e2c"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#1b1b1b"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#2c2c2c"
                        }]
                    }, {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#8a8a8a"
                        }]
                    }, {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#373737"
                        }]
                    }, {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#3c3c3c"
                        }]
                    }, {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#4e4e4e"
                        }]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#757575"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{
                            "color": "#000000"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [{
                            "color": "#264457"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text",
                        "stylers": [{
                            "color": "#fbf8ff"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "color": "#616161"
                        }]
                    }, {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [{
                            "color": "#212121"
                        }, {
                            "visibility": "off"
                        }, {
                            "weight": 3
                        }]
                    }]
                });
                const markerImage = {
                    url: 'https://www.dx-cars.com/dist/images/dxcars-maps-pin.png',
                    scaledSize: new google.maps.Size(80, 74),
                    optimized: false
                }
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(MAPS.lat, MAPS.lang),
                    map: map,
                    icon: markerImage,
                    title: 'dxcar\'s Autovermietung Berlin-Weißensee'
                });
            }
            document.head.appendChild(script);
        }
    }
}