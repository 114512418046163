const CALC = {
    subtotal1: document.getElementById('subtotal-1'),
    subtotal2: document.getElementById('subtotal-2'),
    total: document.getElementById('total'),
    nextButton: document.getElementById('next'),
    updateKmButton: document.getElementById('update-kilometer'),
    upgradeKmCheckbox: document.getElementById('upgrade-freikilometer'),
    onReady: function() {
        CALC.addExtras();
        if ( CALC.upgradeKmCheckbox !== null ) CALC.upgradeKmCheckbox.addEventListener( 'change', CALC.upgradeKm ); 
        if ( CALC.updateKmButton !== null ) CALC.updateKmButton.addEventListener( 'click', CALC.extraKm ); 
    },
    upgradeKm: function(listenForExtraKm = true) {
        let upgradeKmValue            = parseFloat( CALC.upgradeKmCheckbox.value );
        let subtotal1NoExtraKm        = document.getElementById('value-subtotal_no_extra_km');
        let subtotal1NoExtraKmValue   = subtotal1NoExtraKm.value;
        let subtotal1NoExtraKmDecimal = subtotal1NoExtraKmValue.replace(/\./g, '');
        let subtotal1NoExtraKmNumber  = subtotal1NoExtraKmValue.replace(',', '.');
        let subtotal1NoExtraKmInt     = parseFloat( subtotal1NoExtraKmNumber );
        let durationDays              = parseFloat( document.getElementById('value-duration_days').value );
        let priceDay                  = parseFloat( document.getElementById('value-price_day').value );
        let priceDay2                 = parseFloat( document.getElementById('value-price_day_2').value );
        let kmDay                     = parseFloat( document.getElementById('value-km_day').value );
        let kmDay2                    = parseFloat( document.getElementById('value-km_day_2').value );
        let totalKm                   = document.getElementById('value-total_km');
        let kmPerDay                  = document.getElementById('km-per-day');
        let kmTotal                   = document.getElementById('km-total');
        let pricePerDay               = document.getElementById('price-per-day');
        let extraKmInput              = document.getElementById('extra-more-kilometer');
        if ( CALC.upgradeKmCheckbox.checked ) {
            subtotal1NoExtraKm.value = priceDay2 * durationDays;
            totalKm.value = kmDay2 * durationDays;
            kmTotal.textContent = CALC.format(kmDay2 * durationDays, 0, 0);
            kmPerDay.textContent = CALC.format(kmDay2, 0, 0);
            pricePerDay.textContent = CALC.format(priceDay2, 0, 0);
        } else {
            subtotal1NoExtraKm.value = priceDay * durationDays;
            totalKm.value = kmDay * durationDays;
            kmTotal.textContent = CALC.format(kmDay * durationDays, 0, 0);
            kmPerDay.textContent = CALC.format(kmDay, 0, 0);
            pricePerDay.textContent = CALC.format(priceDay, 0, 0);
        }
        CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, 'freikilometer-gesamt', kmTotal.textContent);
        CALC.subtotal1.textContent = subtotal1NoExtraKm.value;
        if ( extraKmInput.value !== '' && listenForExtraKm ) CALC.extraKm();
        if ( extraKmInput.value === '') {
            extraKmInput.value = 0;
            CALC.extraKm();
        }
        CALC.listenTotal();
    },
    extraKm: function() {
        let extraKmInput = document.getElementById('extra-more-kilometer');
        if ( extraKmInput === null) return;
        let extraKmValue = parseFloat(extraKmInput.value);
        if ( Number.isNaN(extraKmValue) ) {
            extraKmInput.classList.add('error');
            return;
        }
        extraKmInput.classList.remove('error');
        let subtotal1NoExtraKm        = document.getElementById('value-subtotal_no_extra_km');
        let subtotal1NoExtraKmValue   = subtotal1NoExtraKm.value;
        let subtotal1NoExtraKmDecimal = subtotal1NoExtraKmValue.replace(/\./g, '');
        let subtotal1NoExtraKmNumber  = subtotal1NoExtraKmValue.replace(',', '.');
        let subtotal1NoExtraKmInt     = parseFloat( subtotal1NoExtraKmNumber );
        let costPerExtraKm            = parseFloat( document.getElementById('value-cost_per_extra_km').value.replace(',', '.') );
        let totalKm                   = parseFloat( document.getElementById('value-total_km').value );
        let extraKmResponse           = document.getElementById('extra-km-response');
        if ( extraKmValue <= totalKm ) {
            if (extraKmResponse !== null) extraKmResponse.remove(); 
            let markup = `<span id='extra-km-response'>${CALC.format(extraKmValue, 0, 0)} km liegen im Kontingent von ${CALC.format(totalKm, 0, 0)} km Gesamt.</span>`;
            extraKmInput.insertAdjacentHTML('afterend', markup);
            if (CALC.upgradeKmCheckbox !== null) {
                CALC.upgradeKm(false)
            } else {
                CALC.subtotal1.textContent = subtotal1NoExtraKmValue;
            }
            CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, 'extra-kilometer', 0);
        } else {
            if (extraKmResponse !== null) extraKmResponse.remove();
            CALC.subtotal1.textContent = CALC.format(subtotal1NoExtraKmInt + ( ( extraKmValue - totalKm ) * costPerExtraKm ), 2, 2);
            let markup = `<span id='extra-km-response'>Es werden ${CALC.format(extraKmValue - totalKm, 0, 0)} km extra auf das vorhandene Kontingent von ${CALC.format(totalKm, 0, 0)} km berechnet. Die Kosten betragen ${ CALC.format(((extraKmValue - totalKm) * costPerExtraKm), 2, 2 ) } €</span>`;
            extraKmInput.insertAdjacentHTML('afterend', markup);
            CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, 'extra-kilometer', extraKmValue - totalKm);
        }
        CALC.listenTotal();
    },
    addExtras: function() {
        let subtotal2Options = document.querySelectorAll('.js-subtotal-2-options');
        subtotal2Options.forEach(option => {
            option.addEventListener('change', () => {
                let stringFormat   = CALC.subtotal2.textContent;
                let decimalFormat  = stringFormat.replace(/\./g, '');
                let numberFormat   = decimalFormat.replace(',', '.');
                let subtotal2Value = parseFloat(numberFormat);
                let optionValue    = parseFloat(option.value);
                if ( option.checked ) {
                    CALC.subtotal2.textContent = CALC.format(subtotal2Value + optionValue, 0, 0);
                    CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, option.id, 'ja');
                } else {
                    CALC.subtotal2.textContent = CALC.format(subtotal2Value - optionValue, 0, 0);
                    CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, option.id, 'nein');
                }
                CALC.listenTotal();
            });
        });
    },
    listenTotal: function() {
        let subtotals = document.querySelectorAll('.js-subtotals');
        var totalValue = 0;
        subtotals.forEach(subtotal => {
            let stringFormat  = subtotal.textContent;
            let decimalFormat = stringFormat.replace(/\./g, '');
            let numberFormat  = decimalFormat.replace(',', '.');
            let value         = parseFloat(numberFormat);
            totalValue += value;
        });
        if (CALC.total === null) return;
        CALC.nextButton.href = CALC.replaceUrlParam(CALC.nextButton.href, 'total', totalValue);
        CALC.total.textContent = CALC.format(totalValue, 2, 2);
    },
    format: function(number, minDecimal, maxDecimal) {
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: minDecimal, maximumFractionDigits: maxDecimal }).format(number);
    },
    replaceUrlParam: function(url, paramName, paramValue) {
        if (paramValue === null) {
            paramValue = '';
        }
        var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
        if ( url.search(pattern) >= 0 ) {
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/,'');
        return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
    }
}